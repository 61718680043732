import { Component, OnInit, HostListener } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } 						from '@angular/common';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: []
})
export class HomeComponent implements OnInit {
	home: any = null;
	projects: any = null;
	activeProject: any = null;
	stories: any = null;
  	activeStory: any = null;
  	story: any = null;
  	lefts: any = ["far-left",/*"left",*/"center",/*"right",*/"far-right"]; //horizontal placement for quote circles
  	lastLeft: any = -1;
  	topOffset: any = 0;
  	headerShapes: any = [];

	constructor(private router: Router,
				private appService: AppService,
				private location: Location
	) {}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if(this.projects) {
			this.generateQuoteOffsets();
			let h = $("#grid").height() - 600;
			this.appService.generateSpriteSpacings(this.headerShapes, -600, h).then((resp) => {
				this.headerShapes = resp;
			});
		}
	}

	ngOnInit(): void {
		//console.log("init");
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		this.appService.getPage('accueil').then((resp) => {
			this.home = resp;

			this.appService.getProjects().then((resp) => {
				this.randomizeProjects(resp);

				this.appService.getStories().then((resp) => {
					this.stories = resp;

					//console.log("stories retreived");
					//console.log(this.stories);

                    //set the multiplier, the top and the left of each quote
                    //bubble at initalization
			        $(this.stories).each((index, value) => {
			           this.stories[index].left = this.getRandomLeft();
			           this.stories[index].multiplier = 0.1;
			           

			           if(index == this.stories.length - 1) {
			           		setTimeout(() => {
			           			this.generateQuoteOffsets();
			           		}, 100);
			           		
			           }
			           //this.topOffset = this.topOffset + 240;
			        });
				});
			});
		});

		this.appService.getStory().subscribe(item => {
	     	this.activeStory = item;
	     	if(this.activeStory !== null) {
	     		// redirect on the current story
                this.location.replaceState('/collaboration/'+this.activeStory.id);

	     	} else if(this.activeStory == null) {
	     		// redirect on the main nav
                this.location.replaceState('/accueil');
	     	}
	    });

	    
	}

	generateQuoteOffsets(): void {
		let topOffset = 0;
		//console.log("generating");
		$(this.stories).each((index, value) => {
			this.stories[index].top = topOffset + "px";

			topOffset = topOffset + (($("#grid").height() - $(window).height()) / (this.stories.length));
		});
	}

	randomizeProjects(projects): void {
		let obj = [[],[]];

		let randIndex;
		let curCol = 0;

		this.appService.addBufferProjects(projects).then((resp) => {
			projects = resp;

			while (projects.length !== 0) {
				let tempAr = [];
				//pick random index;
				randIndex = Math.floor(Math.random() * projects.length);
				//projects[randIndex].imageIndex = Math.floor(Math.random() * projects[randIndex].custom_fields.home_image.length);
				//push to current column
				obj[curCol].push(projects[randIndex]);

				//update column
				curCol = (curCol == 0 ? 1 : 0);

				//remove index from array
				let rest = projects.slice((randIndex)+1 || projects.length);
				projects.length = (randIndex < 0 ? projects.length + randIndex : randIndex);
				projects.push.apply(projects, rest);

				if(projects.length == 0) {
					this.projects = obj;
					//console.log("projects retreived");
					//console.log(this.projects);

					setTimeout(() => {
						let h = $("#grid").height() - 600,
							winH = Math.round($(window).innerHeight()),
							numSprites = Math.ceil(h / winH);
						//console.log(h,winH,numSprites);
						this.appService.generateSpriteGroups(numSprites, -600, h).then((resp) => {
				            this.headerShapes = resp;
				        });
					}, 10);
				}
			}
		});
	}

	goToGrid(): void {
		$('html, body').animate({scrollTop: $('#grid').offset().top }, 400);
	}

    //gets the random left position for the quote bubble
    getRandomLeft(): any {
	  	let index = Math.floor(Math.random() * this.lefts.length);

	  	if(index == this.lastLeft) {
	  		return this.getRandomLeft();
	  	} else {
	  		this.lastLeft = index;
	  		return this.lefts[index];
	  	}
	}

    //gets the current scroll top for for the bubble scrolling parallax
    getTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px)";
    }

    //gets the initial top position of the quote bubble
    getTop(): any{
        return Math.floor((Math.random() * 5) + 1) + "%";
    }

    //sets the story content that will show up on the
    //story details when a quote bubble is clicked
    SetActiveStory(story): void {
        //this.activeStory = story;
        this.appService.setStory(story);
        //alert(story.left);

        $("body").toggleClass("noScroll");
    }

	activateProject(column, index): void {
		this.activeProject = [column, index];
	}

	deactivateProject(): void {
		this.activeProject = null;
	}

	//gets the current scroll top for for the shape formation scrolling parallax
    getHeaderShapeTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px)";
    }
}
