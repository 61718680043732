import { Component, OnInit, Input, HostListener } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { AppService } 					from './app.service';

import { DeviceDetectorService } from 'ngx-device-detector';

// jQuery
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  navOpen: boolean = false;
  current: string;
  quoteOpen: boolean = false;

  home: boolean = false;


  constructor(private appService: AppService,
              private location: Location,
              private deviceService: DeviceDetectorService,
              private router: Router) {
    this.router.events.subscribe((e: any) => {
     // If it is a NavigationEnd event re-initalise the component
     if (e instanceof NavigationEnd) {
       //console.log(e);
       $(window).scrollTop(0);
       if(~e.url.indexOf("accueil")) {
         this.home = true;
       } else {
         this.home = false;
       }
       let url = e.url.split('/');

       //console.log(url[url.length - 2]);
       this.appService.setCurrent(url[1]);

       if(!(url[url.length - 2] == 'originals')) {
         $("body").removeClass("original");
       }
     }
   });
  }

  @HostListener('document:scroll', ['$event'])
    onScroll(event: any) {
      this.addResponsive();
    }

  ngOnInit(): void {
  	//console.log("app");

    if(this.deviceService.isMobile()) {
      $("body").addClass('mobile');
    }

    this.appService.getCurrent().subscribe(item => {
      this.current = item;
    });

    this.addResponsive();
    
  }

  addResponsive(): void {
    let width = $(window).innerWidth();
    if(width < 750)
      $('body').addClass('mobile');
    else
      $('body').removeClass('mobile');
  }

  navToggle(): void {
    this.navOpen = !this.navOpen;

    $("body").toggleClass("noScroll");
  }

  quoteToggle(): void {
    this.quoteOpen = !this.quoteOpen;

    $("body").toggleClass("noScroll");
  }

  goTo(url): void {
    $("body").removeClass("noScroll");
    this.appService.setCurrent(url.slice(1));
    this.router.navigate([url]).then( (e) => {
      if (e) {
        this.navOpen = false;
      }
    });
  }
}
