import { Component, OnInit, HostListener } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery 
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  providers: []
})
export class ProjectComponent implements OnInit {
	projects: any = null;
	shfleProjects: any = null;
	intro: any = null;
	headerShapes: any = [];
	constructor(private router: Router,
				private appService: AppService
	) {}

	@HostListener('window:resize', ['$event'])
    onResize(event) {

        if(this.projects) {
        	let h = $(".list-wrap").height() + $(".section.light-pink").height() - 130;
            this.appService.generateSpriteSpacings(this.headerShapes, -130, h).then((resp) => {
                this.headerShapes = resp;
            });
        }
    }

	ngOnInit(): void {
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		this.appService.getPage('projets').then((resp) => {
			this.intro = resp;
			this.appService.getProjects().then((resp: any) => {
				this.projects = resp;
				this.shfleProjects = this.shuffle(this.projects);
				//console.log("projects retreived")
				//console.log(resp);

				setTimeout(() => {
					let h = $(".list-wrap").height() + $(".section.light-pink").height() - 130,
						winH = Math.round($(window).innerHeight()),
	                    numSprites = Math.ceil(h / winH);

					this.appService.generateSpriteGroups(numSprites, -130, h).then((resp) => {
			            this.headerShapes = resp;
			        });
				}, 10);
			});
		});

		
	}

	shuffle(array) {

		let currentIndex = array.length;
		let temporaryValue, randomIndex;

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;

	};

	//gets the current scroll top for for the shape formation scrolling parallax
    getHeaderShapeTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px)";
    }
}