import { Component, OnInit, ViewEncapsulation, HostListener } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery 
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {
	contact: any = null;
	headerShapes: any = [];
	selectVal: any = null;
	showOther: boolean = false;
	messageSent: boolean = false;
	error: string = "empty";

	constructor(private router: Router,
				private appService: AppService
	) {}

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(this.contact) {
            let h = $(".section .white").height() + 100;
            
            this.appService.generateSpriteSpacings(this.headerShapes, 100, h).then((resp) => {
                this.headerShapes = resp;
            });
        }
    }

	ngOnInit(): void {
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		this.appService.getPage('contact').then((resp) => {
			this.contact = resp;
			//console.log(this.contact);

			setTimeout(() => {
				this.loadIframe();
                let h = $(".section .white").height() + 100,
                    winH = Math.round($(window).innerHeight()),
                    numSprites = Math.ceil(h / winH);

                console.log(h / winH);
                console.log(h,winH,numSprites);
                this.appService.generateSpriteGroups(numSprites, 100, h).then((resp) => {
                    this.headerShapes = resp;
                });
			}, 100);
		});

		
	}

	loadIframe(): void {
		let elems = document.body.getElementsByTagName("figure"),
			i,
			url,
			vid,
			txt;

		if(elems) {
			for (i = 0; i < elems.length; i++) {
				// detect vide embed
				if(!elems[i].className.includes("wp-block-image") && elems[i].children[0].nodeName !== 'IMG') {
					txt = elems[i].innerText.split('/');
					//console.log(txt);
					//if (elems[i].className.includes("is-type-video")) {
						//console.log('video',elems[i]);
						if (elems[i].className.includes("wp-block-embed-vimeo"))
							url = 'https://player.vimeo.com/video/'+txt[3]+'?color=ff4f12&title=0&byline=0&portrait=0';
						else if(elems[i].className.includes("wp-block-embed-youtube")) {
							vid = txt[3].replace("watch?v=", "");
							url = 'https://www.youtube.com/embed/'+vid+'?iv_load_policy=3';
						}
					//}
					else if (elems[i].className.includes("wp-block-embed-soundcloud")) {
						url = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/'+txt[4]+'&color=%23ff4f12&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true';
					}
					//console.log(url);
					elems[i].innerHTML = '<iframe src="'+url+'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
				}
			}
		}
	}

	getHeaderShapeTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px)";
    }

    selectChange(value) {
    	this.selectVal = value;

    	if(this.selectVal == "other") {
    		this.showOther = true;
    	}
    }

    validateForm(): void {
    	this.error = "";
    			
    	if(this.selectVal == "empty" || !this.selectVal) { //check who
    		this.error = "who";
    		$("#who").focus();
    		
    	} else if(this.selectVal == "other" && $("#other").val().length <= 0) {//check other
    		this.error = "other";
    		$("#other").focus();
    	}else if($("#fName").val().length <= 0) { //check first name
    		this.error = "fName";
    		$("#fName").focus();
    	} else if($("#lName").val().length <= 0) { //check last name
    		this.error = "lName";
    		$("#lName").focus();
    	} else if(!this.validateEmail($("#email").val())) { //check email
    		this.error = "email";
    		$("#email").focus();
    	} else if($("#message").val().length <= 0) { //check message
    		this.error = "message";
    		$("#message").focus();
    	} else { //success
    		let info: any = {};
    		
    		//required fields
    		info.who = $("#who option:selected").html() + (this.selectVal == "other" ? ": "+$("#other").val() : "");
    		info.name = $("#fName").val() + " " + $("#lName").val();
    		info.email = $("#email").val();
    		info.message = $("#message").val();

    		//optional fields
    		if($("#tele").val().length > 0)
    			info.phone = $("#tele").val();

    		if($("#org").val().length > 0)
    			info.org = $("#org").val();

    		//console.log(info);
    		this.appService.sendMail(info).then((resp) => {
    			//console.log(resp);
    			this.messageSent = true;

    			setTimeout(() => {
    				$("html, body").animate({scrollTop: $('h2').position().top + 100}, 400)
    			}, 300);

    			
    		});    	
    	}
    }

    validateEmail(email): boolean {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	
		if( !re.test( String( email ).toLowerCase() ) ) {
			return false;
		} else {
			return true;
		}
	};
}