import { Component, OnInit, HostListener } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
  providers: []
})
export class StoryComponent implements OnInit {
	stories: any = null;
	intro: any = null;
	headerShapes: any = [];

	constructor(private router: Router,
				private appService: AppService
	) {}

	@HostListener('window:resize', ['$event'])
    onResize(event) {

        if(this.stories) {
        	let h = $(".list-wrap").height() + $(".section.light-pink").height() - 120;
            this.appService.generateSpriteSpacings(this.headerShapes, -120, h).then((resp) => {
                this.headerShapes = resp;
            });
        }
    }

	ngOnInit(): void {
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		this.appService.getPage('histoires').then((resp) => {
			this.intro = resp;
			this.appService.getStories().then((resp: any) => {
				this.stories = resp;
				//console.log("stories retreived")
				//console.log(resp);

				setTimeout(() => {
					let h = $(".list-wrap").height() + $(".section.light-pink").height() - 120,
						winH = Math.round($(window).innerHeight()),
	                    numSprites = Math.ceil(h / winH);
						
					this.appService.generateSpriteGroups(numSprites, -120, h).then((resp) => {
			            this.headerShapes = resp;
			        });
				}, 10);
			});
		});

		
	}

	//gets the current scroll top for for the shape formation scrolling parallax
    getHeaderShapeTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px)";
    }

    goTo(url): void {
        this.router.navigate([url]);
    }
}
