import { Component, OnInit, ViewEncapsulation, AfterViewInit, HostListener } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } 						from '@angular/common';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class ProjectDetailsComponent implements OnInit {
	project: any = null;
	navProject: any = null;
    stories: any = null;
    storiesReady: boolean = false;
    activeStory: any = null;
    
    lastLeft: any = -1;
    topOffset: any = 0;
    newsHeight: any = 100;
    currentNews: any = []; //the array of currently displayed news
    allNews: any = []; //the array of all news
    newsCounter: any = 1;
    newsFetchLimit: any = 2; //the number of news to get at a time
    moreNews: any = true;
    headerShapes: any = [];

	constructor(private router: Router,
				private appService: AppService,
				private location: Location
	) {}

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // close stories after resize
        $('.showless').each((index, value) => {
            this.showLess(index);
        });

        this.resizeSprite();
    }

	ngOnInit(): void {
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		let id = this.router.url.split("/")[2];
        id = id.split("?")[0];

        /*this.appService.getStories().then((resp) => {

        });*/

        this.appService.getStory().subscribe(item => {
  	        this.activeStory = item;
  	        //console.log(this.activeStory);
  	        if(this.activeStory !== null) {
  	     		// redirect on the current story
  	     		this.location.replaceState('/collaboration/'+(this.activeStory.id ? this.activeStory.id : this.activeStory.ID));

  	     	} else if(this.activeStory == null) {
  	     		// redirect on the main nav
  	     		this.location.replaceState('/action/'+this.project.id);
  	     	}
  	    });

		this.getProject(id);

        //initializes the random shape formations in the header
        
        //console.log(this.headerShapes);
	}

	prepareDOM(): void {
		setTimeout(() => {

            // if ($(".news-content").height() >= 100){
            //     $(".plus").addClass('open').attr('tabindex','0');
            // }

            //check to display "lire plus" button if news is longer than 100px
            $(".rc").each((index, value) => {
                if ($('#nc-' +index+ ' .news-content div').height() >= this.newsHeight){
                    $('#nc-' +index+ ' .plus').addClass('open').attr('tabindex','0');
                }
            });

			$('.wp-block-oacs-slider').each((index, value) => {
				$(value).attr('data-position', 0);
				//console.log();
				$(value).children().first().addClass('active');
			});

			$('.wp-block-oacs-slider').click((e) => {
				let el = e.currentTarget
                //console.log($(el)[0],$(el)[0].offsetHeight);
				if(e.offsetY > $(el)[0].offsetHeight - 100) {
					let middle = $(el)[0].offsetWidth / 2;

					//console.log("middle: "+ middle);
					//console.log("click: "+ e.offsetX);

					if(e.offsetX < middle) {
						this.moveSlider(el, -1);
						//console.log("left arrow clicked");
					} else {
						this.moveSlider(el, 1);
						//console.log("right arrow clicked");
					}


				}
				//console.log($(e));
			});

            setTimeout(() => {
                this.generateQuoteOffsets();
                let h = $("#post-content").height() + $("#related-content").height() - 90,
                    winH = Math.round($(window).innerHeight()),
                    numSprites = Math.ceil(h / winH);
                //console.log(h,winH,numSprites);
                this.appService.generateSpriteGroups(numSprites, -90, h).then((resp) => {
                    this.headerShapes = resp;
                });
            }, 100);

			 this.loadIframe();
		}, 100);
	}

	moveSlider(el, dir): void {

		//current position
		let curPos = parseInt($(el).attr('data-position'));
		//number of slides
		let length = el.children.length;
		if(dir == -1 && curPos != 0) {
			curPos -= 1;
		} else if(dir == 1 && curPos !== length-1) {
			curPos += 1;
		}
		//move
		$(el).children().first().css('margin-left', (100*-curPos) + "%")
		$(el).attr('data-position', curPos);


		$(el).children().removeClass('active');
		//console.log($(el).find(".blocks-gallery-item:nth-of-type("+curPos+")"));
		$(el).find(".blocks-gallery-item:nth-of-type("+(curPos+1)+")").addClass('active');
	}

    ngAfterViewChecked(): void {
        //dynamically add the "lire plus" button to the required news
        $(".rc").each((index, value) => {
            if (($('#nc-' +index+ ' .news-content div').height() >= this.newsHeight) && !($('#nc-' +index+ ' .plus').hasClass("open") || $('#nc-' +index+ ' .moins').hasClass("open"))){
                $('#nc-' +index+ ' .plus').addClass('open').attr('tabindex','0');
            }
        });
    }

    //gets the current scroll top for for the bubble scrolling parallax
    getTopPosition(multiplier): any {
    	//scrolltop > (storyPos - Windowheight)
        //console.log($("#related-content").length);
    	if(this.appService.getScrollTop() >= ($("#related-content").offset().top - $(window).height()))
       		 return "translateY(" + multiplier * (this.appService.getScrollTop() - ($("#related-content").offset().top - $(window).height())) + "px)";
       	else
       		return "translateY(0px)";
    }

    //gets the current scroll top for for the shape formation scrolling parallax
    getHeaderShapeTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px) translateZ(-1px)";
    }

	getProject(id): any {
		$(window).scrollTop(0);
        // reset load more news
        this.currentNews = [];
        this.newsCounter = 1;
        
		this.location.replaceState('/action/'+id);
		this.appService.getProjectByID(id).then((resp: any) => {
			this.project = resp;
            //console.log(this.project.custom_fields.end_date);
            this.storiesReady = false;
			this.stories = this.project.custom_fields.project_story_relationship;

            this.allNews = this.project.custom_fields.project_news_relationship;
            //console.log(this.allNews);
            if(this.allNews.length > 0){
                this.allNews.sort((a, b) => {
                    return Date.parse(b.post_date_gmt)/1000 - Date.parse(a.post_date_gmt)/1000;
                });


                if(this.project.custom_fields.end_date && 
                    this.project.custom_fields.end_date.length > 0) {
                    this.allNews.reverse();
                }
            }

            
            let i;
            //gets the initial news to be displayed
            for (i = 0; i < this.newsFetchLimit; i++) {
                if (this.allNews[i] != null){
                    this.currentNews[i] = this.allNews[i];
                }
            }
            //determines whether the "plus" buttons needs to be shown under the news
            if (this.allNews.length <= this.newsFetchLimit){
                this.moreNews = false;
            }
            else{
                this.moreNews = true;
            }
			//console.log("project retreived")
			//console.log(resp);

			//console.log("stories retreived");
            //console.log(this.stories);
            this.topOffset = 0;
            $(this.stories).each((index, value) => {
                this.stories[index].left = this.appService.getRandomLeft();
                this.stories[index].multiplier = 0.1;
                this.stories[index].top = this.topOffset + "px";
                this.topOffset = this.topOffset + 120;

                if(index == (this.stories.length - 1) ) {
                    setTimeout(() => {
                        this.storiesReady = true;
                    }, 100);
                    
                }
            });
            //console.log(this.stories);

			// parse content
			this.appService.getPrevNextProject(id).then((resp: any) => {
				this.navProject = resp;
				//console.log(this.navProject);
			});

			setTimeout(() => {
				this.prepareDOM();
			}, 10);

			//this.prepareDOM();

		});
		return false;
	}

	loadIframe(): void {
        //console.log('load iframe');
		let elems = document.body.getElementsByTagName("figure"),
			i,
			url,
			vid,
			txt;

		if(elems) {
			for (i = 0; i < elems.length; i++) {
				// detect vide embed
				if(!elems[i].className.includes("wp-block-image") && elems[i].children[0].nodeName !== 'IMG') {
					txt = elems[i].innerText.split('/');
					//console.log( elems[i],txt);

                    if(txt[3] || txt[4]) {
					//if (elems[i].className.includes("is-type-video")) {
						//console.log('video',elems[i]);
						if (elems[i].className.includes("wp-block-embed-vimeo"))
							url = 'https://player.vimeo.com/video/'+txt[3]+'?color=ff4f12&title=0&byline=0&portrait=0';
						else if(elems[i].className.includes("wp-block-embed-youtube")) {
							vid = txt[3].replace("watch?v=", "");
							url = 'https://www.youtube.com/embed/'+vid+'?iv_load_policy=3';
						}
					//}
    					if (elems[i].className.includes("wp-block-embed-soundcloud")) {
    						url = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/'+txt[4]+'&color=%23ff4f12&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true';
    					}

                        elems[i].innerHTML = '<iframe src="'+url+'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
                    }
				}
			}
		}
	}

    //sets the story content that will show up on the
    //story details when a quote bubble is clicked
    SetActiveStory(story): void {
        //this.activeStory = story;
        //console.log("hello");
        console.log(story);
        this.appService.setStory(story);
        //alert(story.left);

        $("body").toggleClass("noScroll");
    }

    //fetches more news to be displayed
    loadMoreNews(): void {
        let i;
        if(this.moreNews){
            for (i = 0; i < this.newsFetchLimit; i++) {
                if (this.newsCounter < this.allNews.length){
                    this.currentNews[this.newsCounter] = this.allNews[this.newsCounter];
                    this.newsCounter = this.newsCounter + 1;
                     setTimeout(() => {
                        this.loadIframe();
                        this.generateQuoteOffsets();
                        setTimeout(() => {
                            this.resizeSprite();
                        }, 100);
                    }, 100);
                }
            }
        }
        if(this.currentNews.length == this.allNews.length){
            this.moreNews = false;
        }
    }

	showMore(id): any {
		$('#nc-' +id+ ' .news-content, #nc-'+id+ ' .moins').addClass('open');
		$('#nc-' +id+ ' .plus').removeClass('open').attr('tabindex','-1');
		$('#nc-' +id+ ' .moins').attr('tabindex','0');
        $('#nc-' +id+ ' .news-content').height($('#nc-' +id+ ' .news-content div').height());
        setTimeout(() => {
            this.generateQuoteOffsets();
            setTimeout(() => {
                this.resizeSprite();
            }, 100);
        }, 100);
		return false;
	}

	showLess(id): any {
		$('#nc-' +id+ ' .news-content, #nc-'+id+ ' .moins').removeClass('open');
		$('#nc-' +id+ ' .plus').addClass('open').attr('tabindex','0');
		$('#nc-' +id+ ' .moins').attr('tabindex','-1');
        $('#nc-' +id+ ' .news-content').height(this.newsHeight);
        setTimeout(() => {
            this.generateQuoteOffsets();
            setTimeout(() => {
                this.resizeSprite();
            }, 100);
        }, 200);
		return false;
	}

    goTo(url): void {
        this.router.navigate([url]).then( (e) => {

        });
    }

    generateQuoteOffsets(): void {
        let topOffset = 0;
        $(this.stories).each((index, value) => {
            this.stories[index].top = topOffset + "px";
            let relcont = $("#related-content").height(),
                winH = $(window).height(),
                total = this.stories.length;
            
            topOffset = topOffset + (relcont / total);
        });
    }

    resizeSprite(): void {
        let h = $("#post-content").height() + $("#related-content").height() - 90;
        this.appService.generateSpriteSpacings(this.headerShapes, -90, h).then((resp) => {
            this.headerShapes = resp;
        });
    }
}
