import { Component, OnInit, Input } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } 						from '@angular/common';
//import { Input }  from '@angular/forms';
//import {NgForm} from '@angular/forms';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'story-details',
  templateUrl: './story-details.component.html',
  styleUrls: ['./story-details.component.scss'],
  providers: []
})
export class StoryDetailsComponent implements OnInit {
	project: any = null;
    quoteOpen: boolean = false;
    @Input() story: any;
    initialLoad: boolean = false;
    headerShapes: any = [];

	constructor(private router: Router,
				private appService: AppService,
				private location: Location
	) {}

	ngOnInit(): void {
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		let id = this.router.url.split("/")[2];
		let section = this.router.url.split("/")[1];

		//console.log(id);

		if(this.story) {
			//console.log(this.story);
		} else if(section == 'collaboration' && id) {
			this.initialLoad = true;
			this.appService.getStoryByID(id).then((resp: any) => {
				this.story = resp;
				//console.log("story retreived");
				console.log(resp);
			});
		}
	}

    quoteToggle(): void {
        this.quoteOpen = !this.quoteOpen;
        this.appService.setStory(null);

        if(this.initialLoad) {
            this.router.navigate(['/collaborations']).then( (e) => {

            });
        }

        if(this.quoteOpen) {
        	$("body").removeClass("noScroll");
        } else {
        	$("body").addClass("noScroll");
        }    
    }

    getRandomLeft(): any {
        return Math.floor((Math.random() * 7) + 1) * 10;
    }
}
