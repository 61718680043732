import { Injectable, OnInit, Output, EventEmitter, HostListener }   from '@angular/core';
import { DomSanitizer, SafeHtml } 			from '@angular/platform-browser';
import { Location } 						from '@angular/common';


import { Router } 				from '@angular/router';
import { Http } 				from '@angular/http';
import { HttpHeaders, HttpClient }		    from '@angular/common/http';

import { Observable, of } from 'rxjs';

// jQuery
declare let jquery:any;
declare let $ :any;

const httpOptions: any = new HttpHeaders({
							'Content-Type': 'application/json',
							'Access-Control-Allow-Origin': '*'
						});


@Injectable()
export class AppService implements OnInit {
	@Output() story: EventEmitter<any> = new EventEmitter();
	@Output() current: EventEmitter<string> = new EventEmitter();
	base_url: string = null;
	cms_url: string = null;
	private lastLeft: any = -1;
	private lastColorIndexes: any = [-1, -1];
	private patternColors: any = ["yellow","orange", "pink", "lightblue", "blue", "green",  "lightpurple"];
	private shapeSizes: any = ["big", "medium", "small"]
	private lefts: any = ["left","right"]; //horizontal placement for quote circles
	private projects: any = null;
	stories: any = null;
	members: any = null;

	headers: any = new Headers();
	scroll: boolean = false;

	private scrollTop: any = 10;

	constructor(private http: Http,
				private httpClient: HttpClient,
				private router: Router,
				private location: Location,
				public sanitizer: DomSanitizer,) {}

	ngOnInit(): void {
		this.getBaseUrl();
		this.getCMSUrl();

		this.headers = new HttpHeaders().set('Access-Control-Allow-Origin', "*");
	}

	handleScroll(event): any{
		this.scrollTop = window.scrollY;
	}

	getScrollTop(): any {
		return this.scrollTop;
	}

	getBaseUrl(): string {
		if(this.base_url == null) {
			// define base_url
			let getUrl = window.location;
			let temp =  getUrl.pathname.split('/');

			if(getUrl.host == "localhost:4200") {
			  // ng serve
			  this.base_url = getUrl .protocol + "//" + getUrl.host;
			} else {
			  // ng build
			  this.base_url = getUrl .protocol + "//" + getUrl.host + "/dist";
			}
		}
		return this.base_url;
	}

	getCMSUrl(): string {
		if(this.cms_url == null) {
			// define base_url
			let getUrl = window.location;
			let temp =  getUrl.pathname.split('/');

			if(getUrl.host == "localhost:4200") {
			  // ng serve
			  this.cms_url = getUrl .protocol + "//" + getUrl.host;
			} else {
			  // ng build
			  this.cms_url = getUrl .protocol + "//" + getUrl.host + "/cms/api";
			}
		}
		return this.cms_url;
	}

	//for injecting HTML into the DOM
	sanitize(content): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

	//this uses the JSON API to grab a wordpress page's data, given a slug
	getPage(slug): Promise<any> {
		return this.http
			//.get(this.getCMSUrl()+'/get_page/?slug='+slug, httpOptions)
			.get(this.getCMSUrl()+'/pages/get_page?slug='+slug, httpOptions)
			.toPromise()
			.then((response: any) => {
				response = JSON.parse(JSON.parse(response._body));
				return response.page as any;
			});
	}

	getProjects(): Promise<any> {
		if(!this.projects) {
			return this.http
				.get(this.getCMSUrl()+'/projects/get_projects', httpOptions)
				.toPromise()
				.then((response: any)  => {

					response = JSON.parse(JSON.parse(response._body));
					this.projects = response.projects;
					return response.projects.slice() as any;
				});
		} else {
			//console.log(this.projects);
			return of(this.projects.slice()).toPromise() as any;
		}
	}

	getProjectByID(id): Promise<any>{
		return this.getProjects().then((resp: any) => {
			//console.log(this.projects);

			for(let i = 0; i < this.projects.length; i++) {
				if(id == this.projects[i].id) {
					return of(this.projects[i]).toPromise() as any;
				} else if(i == this.projects.length - 1) {
					return false as any;
				}
			}

			//return false as any;
		});
	}

	getPrevNextProject(id) {
		return this.getProjects().then((resp: any) => {
			let nav = {'prev':  {'id': '', 'title': ''}, 'next': {'id': '', 'title': ''}};

			for(let i = 0; i < this.projects.length; i++) {
				if(id == this.projects[i].id) {
					if(i >= 1) {
						nav['prev'].id = this.projects[i-1].id;
						nav['prev'].title = this.projects[i-1].title;
					} else {
						nav['prev'].id = this.projects[this.projects.length-1].id;
						nav['prev'].title = this.projects[this.projects.length-1].title;
					}
					if(i < this.projects.length-1) {
						nav['next'].id = this.projects[i+1].id;
						nav['next'].title = this.projects[i+1].title;
					} else {
						nav['next'].id = this.projects[0].id;
						nav['next'].title = this.projects[0].title;
					}
					return of(nav).toPromise() as any;
				} else if(i == this.projects.length - 1) {
					return false as any;
				}
			}
		});
	}

	getStories(): Promise<any> {
		if(!this.stories) {
			return this.http
				.get(this.getCMSUrl()+'/stories/get_stories', httpOptions)
				.toPromise()
				.then((response: any)  => {
					response = JSON.parse(JSON.parse(response._body));
					//console.log(response);
					this.stories = response.stories;
					return this.stories.slice() as any;
				});
		} else {
			return of(this.stories.slice()).toPromise() as any;
		}
	}

	getStoryByID(id): Promise<any>{
		return this.getStories().then((resp: any) => {

			for(let i = 0; i < this.stories.length; i++) {
				//console.log(id + " == "+ this.stories[i].id);
				if(id == this.stories[i].id) {
					return of(this.stories[i]).toPromise() as any;
				} else if(i == this.stories.length - 1) {
					return false as any;
				}
			}
		});
	}

	getMembers(): Promise<any> {
		if(!this.members) {
			return this.http
				.get(this.getCMSUrl()+'/members/get_members', httpOptions)
				.toPromise()
				.then((response: any)  => {
					response = JSON.parse(JSON.parse(response._body));
					//console.log(response);
					this.members = response.members;
					return this.members as any;
				});
		} else {
			return of(this.members).toPromise() as any;
		}
	}

	setStory(view): void {
		this.story.emit(view);
	}

	getStory(): EventEmitter<any> {
		return this.story;
	}

	setCurrent(view): void {
		this.current.emit(view);
	}

	getCurrent(): EventEmitter<any> {
		return this.current;
	}

	addBufferProjects(projects): Promise<any> {
		let obj = [];
		for(let i = 0; i < projects.length; i++) {
			for(let j = 0; j < projects[i].images.length; j++) {
				let project = Object.assign({},projects[i]);
				project.image = projects[i].images[j];
				obj.push(project);
				if(i == projects.length - 1 && j == projects[i].images.length - 1) {
					return of(obj).toPromise() as any;
				}
			}
		}
	}
	// create shorten quote
	shortenQuote(string) {
		if(string) {
			let maxLength = 100 // maximum number of characters to extract

			// trim the string to the maximum length
			let trimmedString = this.strip_html_tags(string).substr(0, maxLength);

			// remove quote
			trimmedString = trimmedString.replace('« ', '');

			//re-trim if we are in the middle of a word
			trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + " ...";
			
			return trimmedString;
		}
	}
	// remove html tags
	strip_html_tags(string) {
	   if ((string===null) || (string===''))
	       return false;
	  else
	   string = string.toString();
	  return string.replace(/<[^>]*>/g, '');
	}

	sendMail(info: any): Promise<any> {
		return this.http
					.post(this.getCMSUrl()+'/contact/send_email', info, httpOptions)
					.toPromise()
					.then((response: any) => {
						response = JSON.parse(JSON.parse(response._body));
						//console.log(response);
						return response.success as any;
					});

	}

	//gets the random left position for the quote bubble
	getRandomLeft(): any {
        let index = Math.floor(Math.random() * this.lefts.length);

        if(index == this.lastLeft) {
            return this.getRandomLeft();
        } else {
            this.lastLeft = index;
            return this.lefts[index];
        }
    }

    //gets a random index for shapes
    // 0, 17 is blank shapes
    // 1-16 is a shape
    getShapeIndex(): any {
        return Math.floor(Math.random() * 18);
    }

    //gets a random shape size
    getShapeSize(big): any {
        let chosenSizeIndex = Math.floor(Math.random() * this.shapeSizes.length);

       	if(!big || (big && this.shapeSizes[chosenSizeIndex] !== 'big'))
        	return this.shapeSizes[chosenSizeIndex];
        else 
        	return this.getShapeSize(big);
    }

    //gets a random color for the shapes
    getRandomColor(): any {
        let chosenColorIndex = Math.floor(Math.random() * this.patternColors.length);
        if(this.lastColorIndexes.includes(chosenColorIndex)) {
        	return this.getRandomColor();
        } else {
        	this.lastColorIndexes[0] = this.lastColorIndexes[1];
        	this.lastColorIndexes[1] = chosenColorIndex;
        	//this.lastColorIndexes = chosenColorIndex;
        	return this.patternColors[chosenColorIndex];
        }
    }

    generateSpriteSpacings(arr, offset, height): Promise<any> {
    	let inc = (height - offset) / (arr.length+1);
    	console.log(arr, offset, height, inc);
    	for(let i = 0; i < arr.length; i++) {
    		arr[i].top = offset + "px";
    		offset += inc;

    		if(i == arr.length - 1) {
    			return of(arr).toPromise() as any;
    		}
    	}
    }

	generateSpriteGroups(totalGroups, offset, height): Promise<any> {
		
		let arr = [];

		for(let i = 0; i < totalGroups; i++) {
			let tempShape: any = {};
			let big: boolean = false;

			//tempShape.top = offset + "px";
			//offset += 215;
			tempShape.left = this.getRandomLeft();
			tempShape.chosenColor = this.getRandomColor();
			tempShape.shapeIndexes = [];
			tempShape.chosenSizes = [];
			tempShape.multiplier = 0.3;

			for (let j = 0; j < 4; j++) {
	            tempShape.shapeIndexes[j] = this.getShapeIndex();
	        }

	        for (let k = 0; k < 4; k++) {
	            tempShape.chosenSizes[k] = this.getShapeSize(big);
	            if(tempShape.chosenSizes[k] == 'big') {
	            	big = true;
	            } else if(k == 3 && !big) {
	            	let chosenIndex = Math.floor(Math.random() * 4);
	            	tempShape.chosenSizes[chosenIndex] = 'big';
	            }
	        }

	        arr.push(tempShape);

	        if(i == totalGroups - 1) {

	        	return this.generateSpriteSpacings(arr, offset, height).then((resp) => {
	        		return of(resp).toPromise() as any;
	        	});
	        }
		}
	}
}
