import { Component, OnInit, HostListener } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery 
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
  providers: []
})
export class CreditsComponent implements OnInit {
	credits: any = null;
	headerShapes: any = [];

	constructor(private router: Router,
				private appService: AppService
	) {}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if(this.credits) {
			let h = $(".section .white").height() + 100;
			this.appService.generateSpriteSpacings(this.headerShapes, 100, h).then((resp) => {
				this.headerShapes = resp;
			});
		}
	}

	ngOnInit(): void {
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		this.appService.getPage('credits').then((resp) => {
			this.credits = resp;
			//console.log(this.credits);
			setTimeout(() => {
				let h = $(".section .white").height() + 100,
					winH = Math.round($(window).innerHeight()),
                    numSprites = Math.ceil(h / winH);

				this.appService.generateSpriteGroups(numSprites, 100, h).then((resp) => {
		            this.headerShapes = resp;
		        });
	        }, 10);

		});

		
	}

	getHeaderShapeTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px)";
    }
}