import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent }   from './app.component';

import { HomeComponent }  from './home/home.component';
import { AboutComponent }  from './about/about.component';
import { CreditsComponent }  from './credits/credits.component';
import { ContactComponent } from './contact/contact.component';
import { MemberComponent }  from './member/member.component';

import { ProjectComponent }  from './project/project.component';
import { ProjectDetailsComponent }  from './project/project-details.component';

import { StoryComponent }  from './story/story.component';
import { StoryDetailsComponent }  from './story/story-details.component';

const routes: Routes = [
	/*{ path: '', redirectTo: '/en', pathMatch: 'full' },
	{ path: ':lang',    component: AppComponent },
	{ path: ':lang/story/:name', component: AppComponent},
	{ path: ':lang/about',    component: AppComponent },
	{ path: ':lang/credits',    component: AppComponent },
	{ path: ':lang/stories',    component: AppComponent }*/
	{ path: '',  redirectTo: '/accueil',  pathMatch: 'full' },
	{ path: 'accueil',  component: HomeComponent },
	{ path: 'a-propos', component: AboutComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'credits', component: CreditsComponent },
	{ path: 'membres', component: MemberComponent },
	{ path: 'actions', component: ProjectComponent },
	{ path: 'action/:id', component: ProjectDetailsComponent },
	{ path: 'collaborations', component: StoryComponent },
	{ path: 'collaboration/:id', component: StoryDetailsComponent }
];

@NgModule({
  imports: [ 
  	RouterModule.forRoot(routes, {
  		anchorScrolling: 'enabled'
  	}) 
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
	
}