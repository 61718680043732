import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule, Http }                     from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppRoutingModule } 						from './app-routing.module';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { CreditsComponent } from './credits/credits.component';
import { ContactComponent } from './contact/contact.component';
import { MemberComponent } from './member/member.component'; 


import { ProjectComponent } from './project/project.component';
import { ProjectDetailsComponent } from './project/project-details.component';

import { StoryComponent } from './story/story.component';
import { StoryDetailsComponent } from './story/story-details.component';

import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    CreditsComponent,
    ContactComponent,
    MemberComponent,
    ProjectComponent,
    ProjectDetailsComponent,
    StoryComponent,
    StoryDetailsComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [ AppService ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
