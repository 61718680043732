import { Component, OnInit, HostListener } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery 
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
  providers: []
})
export class MemberComponent implements OnInit {
	members: any = null;
	intro: any = null;
	headerShapes: any = [];

	constructor(private router: Router,
				private appService: AppService
	) {}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if(this.members) {
			let h = $(".list-wrap").height() + $(".section.light-pink").height() - 120;
			this.appService.generateSpriteSpacings(this.headerShapes, -120, h).then((resp) => {
				this.headerShapes = resp;
			});
		}
	}

	ngOnInit(): void {
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		this.appService.getPage('membres').then((resp) => {
			this.intro = resp;
			this.appService.getMembers().then((resp: any) => {
				this.members = resp;
				//console.log("members retreived")
				//console.log(resp);

				setTimeout(() => {
					let h = $(".list-wrap").height() + $(".section.light-pink").height() - 120,
						winH = Math.round($(window).innerHeight()),
	                    numSprites = Math.ceil(h / winH);

					//console.log(num);
					this.appService.generateSpriteGroups(numSprites, -120, h).then((resp) => {
			            this.headerShapes = resp;
			        });
				}, 10);

				
			});
		});

		
	}

	//gets the current scroll top for for the shape formation scrolling parallax
    getHeaderShapeTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px)";
    }
}