import { Component, OnInit, ViewEncapsulation, HostListener } 				from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AppComponent } 		from '../app.component';
import { AppService }			from './../app.service';

// jQuery 
declare let jquery:any;
declare let $ :any;

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {
	about: any = null;
	headerShapes: any = [];

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if(this.about) {
			let h = $(".section.white").height() - 100;
			this.appService.generateSpriteSpacings(this.headerShapes, -100, h).then((resp) => {
				this.headerShapes = resp;
			});
		}
	}

	constructor(private router: Router,
				private appService: AppService
	) {}

	ngOnInit(): void {
		if(!this.appService.base_url)
			this.appService.getBaseUrl();

		this.appService.getPage('a-propos').then((resp) => {
			this.about = resp;
			//console.log(this.about);

			setTimeout(() => {
				this.loadIframe();
				let h = $(".section.white").height() - 100,
					winH = Math.round($(window).innerHeight()),
                    numSprites = Math.ceil(h / winH);
				//console.log(h,winH,numSprites);	
				this.appService.generateSpriteGroups(numSprites, -100, h).then((resp) => {
		            this.headerShapes = resp;
		        });
			}, 10);
		});

		
	}

	loadIframe(): void {
		let elems = document.body.getElementsByTagName("figure"),
			i,
			url,
			vid,
			txt;

		if(elems) {
			for (i = 0; i < elems.length; i++) {
				// detect vide embed
				if(!elems[i].className.includes("wp-block-image") && elems[i].children[0].nodeName !== 'IMG') {
					txt = elems[i].innerText.split('/');
					//console.log(txt);
					//if (elems[i].className.includes("is-type-video")) {
						//console.log('video',elems[i]);
						if (elems[i].className.includes("wp-block-embed-vimeo"))
							url = 'https://player.vimeo.com/video/'+txt[3]+'?color=ff4f12&title=0&byline=0&portrait=0';
						else if(elems[i].className.includes("wp-block-embed-youtube")) {
							vid = txt[3].replace("watch?v=", "");
							url = 'https://www.youtube.com/embed/'+vid+'?iv_load_policy=3';
						}
					//}
					else if (elems[i].className.includes("wp-block-embed-soundcloud")) {
						url = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/'+txt[4]+'&color=%23ff4f12&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true';
					}
					//console.log(url);
					elems[i].innerHTML = '<iframe src="'+url+'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
				}
			}
		}
	}

	getHeaderShapeTopPosition(multiplier): any {
        return "translateY(" + multiplier * this.appService.getScrollTop() + "px)";
    }
}